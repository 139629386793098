<template>
    <draggable
        v-bind="dragOption"
        :list="list" :value="value"
        @change="onChange"
        @input="emitter">
        <kurcc-question
            v-for="(question, index) in questions"
            :key="++question.id"
            :class="{'d-none': isRepeatableSectionChild(question)}"
            :grandparent-index="grandparentIndex"
            :is-less-than-max-level="isLessThanMaxLevel"
            :level="level"
            :page-index="pageIndex"
            :parent-index="parentIndex"
            :question="question"
            :question-index="index"
            :show-bottom-border="showBottomBorder(question, index)"
            :show-top-border="showTopBorder(index)">
            <kurcc-question-list
                :key="--question.id"
                v-if="isLessThanMaxLevel"
                :drag-option="getDragOptions(question)"
                :grandparent-index="parentIndex"
                :level="nextLevel()"
                :list="question.questions"
                :page-index="pageIndex"
                :parent-index="index"
                :parent-is-last-item-in-list="isLastItemInArray(index, questions)"
                class="item-sub"
                @change="onChange"/>
        </kurcc-question>
    </draggable>
</template>

<script>
import {isFirstItemInArray, isLastItemInArray} from '@/modules/app/helpers/utils';
import {isQuestionTypeCheck, isQuestionTypeSection} from '@/modules/forms/helpers/helpers';
import {QuestionType} from '@/modules/forms/enums/question-type';

export default {
    name: "KurccQuestionList",
    components: {
        KurccQuestion: () => import('@/modules/forms/components/question/KurccQuestion'),
        draggable: () => import('vuedraggable')
    },
    props: {
        pageIndex: {
            type: Number,
            required: true
        },
        value: {
            required: false,
            type: Array,
            default: null
        },
        list: {
            required: false,
            type: Array,
            default: null
        },
        dragOption: {
            required: false,
        },
        parentIndex: {
            type: Number,
            required: false
        },
        grandparentIndex: {
            type: Number,
            required: false
        },
        parentIsLastItemInList: {
            type: Boolean
        },
        level: {
            type: Number,
            required: false,
            default: 0
        }
    },
    data() {
        return {
            isQuestionTypeCheck,
            isQuestionTypeSection,
            isFirstItemInArray,
            isLastItemInArray,
            QuestionType,
            maxLevel: 2
        }
    },
    computed: {
        sectionDragOptions() {
            return {
                animation: 0,
                group: {
                    name: 'section',
                    put: true,
                    pull: true,
                    // put: function (to, from) {
                    //     console.log(from.options)
                    //     return from.options.group.name === 'section' || from.options.group.name === 'check';
                    // },
                },
                disabled: false,
                ghostClass: "ghost",
                handle: ".drag-handle",
                // swapThreshold: '0.5',
                invertedSwapThreshold: "0.5"
            };
        },
        checkDragOptions() {
            return {
                animation: 0,
                group: {
                    name: 'check',
                    pull: true,
                    put: false
                    // put: function (to, from) {
                    // console.log('to: ', to)
                    // console.log('from: ', from)
                    // console.log(from.el.children.length)
                    // return from.el.children.length === 1;
                    // },
                    // put: false,
                },
                disabled: false,
                ghostClass: "ghost",
                handle: ".drag-handle",
                // swapThreshold: '0.5',
                invertedSwapThreshold: "0.5"
            }
        },
        questions() {
            return this.value ? this.value : this.list;
        },
        isChild() {
            return this.level > 0
        },
        isFirstLevelChild() {
            return this.level - 1 === 0
        },
        isLessThanMaxLevel() {
            return this.level < this.maxLevel
        },
        showBottomBorder() {
            return (question, index) => !this.isChild ||
                (this.isChild && this.isFirstItemInArray(index) && this.haveChildren(question)) ||
                (this.isChild && (this.haveMoreThanOneChild(question) || !this.isLastItemInArray(index, this.questions))) ||
                (this.isFirstLevelChild && this.isLastItemInArray(index, this.questions) && this.parentIsLastItemInList) ||
                (this.isFirstLevelChild && this.haveChildren(question)) ||
                (this.isChild && this.isLastItemInArray(index, this.questions) && this.parentIsLastItemInList)
        },
        showTopBorder() {
            return index => (this.isFirstItemInArray(index) && !this.isChild) ||
                (!this.isFirstItemInArray(index) && this.previousQuestionHaveChild(index))
        },
    },
    watch: {
        dragOption: val => console.log(val)
    },
    methods: {
        emitter(value) {
            this.$emit("input", value);
        },
        onChange: function () {
            if (this.isChild) {
                this.$emit("change");
            } else {
                this.emitter(this.value);
            }
        },
        getDragOptions(question) {
            return question.type === QuestionType.check ? this.checkDragOptions : this.sectionDragOptions
        },
        haveChildren(question) {
            return question.questions.length > 0
        },
        haveMoreThanOneChild(question) {
            return question.questions.length > 1
        },
        previousQuestionHaveChild(index) {
            return this.questions[index - 1].questions.length > 0
        },
        nextLevel() {
            return this.level + 1
        },
        isRepeatableSectionChild(question) {
            return !!question.options.parentId
        }
    }
}
</script>

<style lang="scss" scoped>
.item-sub {
    margin: 0 0 0 2rem;
}
</style>
